// Internal Imports
import { camelCaseKeysDeep } from '../../utils/dataUtils.mjs';
import { authenticatedRequest } from './authenticatedRequest.mjs';

// Local Functions
const fetchCurrentUser = async ({ locale }) => {
  const response = await authenticatedRequest(`v1.2.0/${locale}/users/me`);
  return camelCaseKeysDeep(response.data.user);
};

// Module Exports
export { fetchCurrentUser };
