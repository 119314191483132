// Internal Imports
import {
  FETCH_USER_REQUESTED,
  FETCH_USER_SUCCEEDED,
  FETCH_USER_ERRORED,
} from '../config/types/action.mjs';
import { user as defaultState } from '../config/defaultState.mjs';

// Local Functions
function userReducer(state = defaultState, { type, payload } = {}) {
  switch (type) {
    case FETCH_USER_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_USER_SUCCEEDED:
      return {
        ...state,
        error: undefined,
        isLoading: false,
        remoteData: payload,
      };
    case FETCH_USER_ERRORED:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    default:
      return state;
  }
}

// Module Exports
export { userReducer };
