// External Imports
import { LOCATION_CHANGE, replace } from 'connected-react-router';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

// Internal Imports
import {
  actionReceivePrimaryContent,
  actionReceiveError,
  actionReceiveSiteOptions,
  actionReceiveSiteOptionsError,
  actionRequestArchive,
  actionRequestContentItem,
  actionRequestSiteOptions,
  actionRequestNextPageFetchInitiated,
} from '../actions/index.mjs';
import { REQUEST_NEXT_PAGE, FETCH_USER_REQUESTED } from '../config/types/action.mjs';
import { selectActivePagination } from '../selectors/selectActivePagination.mjs';
import { selectMatch } from '../selectors/selectMatch.mjs';
import { selectRedirect } from '../selectors/selectRedirect.mjs';
import { selectHasFetchedActiveContent } from '../selectors/selectHasFetchedActiveContent.mjs';
import { selectContentGroupTypes } from '../selectors/selectContentGroupTypes.mjs';
import { logger, reportWordPressFetchingError } from '../utils/rollbar.mjs';
import { fetchUser } from './userSaga.mjs';
import { selectIsRoute404 } from '../selectors/selectIs404.mjs';
import {
  fetchAuthor,
  fetchCategoryPageInfo,
  fetchContentItem,
  fetchLandingPage,
  fetchOptions,
  fetchSearch,
  fetchTagsPageInfo,
} from '../services/wordpress/index.mjs';
import {
  AUTHOR_ARCHIVE,
  CATEGORY_ARCHIVE,
  HOME,
  SEARCH_ARCHIVE,
  TERM_ARCHIVE,
} from '../config/types/path.mjs';
import { DEFAULT_SEARCH_ORDER } from '../config/constants.mjs';

// Local Functions
/**
 * Dynamic saga to be run after Redux store creation.
 */
function* rootSaga() {
  try {
    // Combine all continuous sagas, and run them in parallel.
    yield all([
      takeLatest(LOCATION_CHANGE, watchLocationChanges),
      takeLatest(REQUEST_NEXT_PAGE, watchRequestNextPage),
      takeLatest(FETCH_USER_REQUESTED, fetchUser),
    ]);
  } catch (error) {
    yield put(actionReceiveError(error));
    logger.error('Unexpected error happened in a redux saga.', error);
  }
}

/**
 * Emit site options and related side effects.
 * @param {Object} service remote service implementation
 */
function* requestSiteOptions() {
  const contentGroupTypes = yield select(selectContentGroupTypes);
  const hasGroupTypes = contentGroupTypes.every((group) => group.length);
  if (!hasGroupTypes) {
    yield put(actionRequestSiteOptions());
    try {
      const siteOptions = yield call(fetchOptions);
      yield put(actionReceiveSiteOptions(siteOptions));
    } catch (error) {
      reportWordPressFetchingError('Error fetching site options', error);
      yield put(actionReceiveSiteOptionsError(error));
    }
  }
}

function* fetchArchive() {
  const {
    params: { language, slug },
    pathType,
    query,
  } = yield select(selectMatch);
  const { perPage, currentPage } = yield select(selectActivePagination);
  switch (pathType) {
    case TERM_ARCHIVE:
      return yield call(fetchTagsPageInfo, {
        language,
        page: currentPage,
        perPage,
        slug,
      });
    case CATEGORY_ARCHIVE:
      return yield call(fetchCategoryPageInfo, {
        language,
        page: currentPage,
        perPage,
        slug,
      });
    case AUTHOR_ARCHIVE:
      return yield call(fetchAuthor, {
        language,
        name: slug,
        page: currentPage,
        perPage,
      });
    case SEARCH_ARCHIVE:
      return yield call(fetchSearch, {
        language,
        order: query.order ?? DEFAULT_SEARCH_ORDER,
        page: currentPage,
        perPage,
        search: query.s,
      });

    case HOME:
      return yield call(fetchLandingPage, {
        language,
        page: currentPage,
        perPage,
      });
    default:
      throw new Error('Unknown archive path received');
  }
}

/**
 * Emit location change side effects.
 * @param {Object} service remote service implementation
 */
function* watchLocationChanges() {
  if (yield select(selectIsRoute404)) {
    return;
  }
  const {
    isArchive,
    params: { language, slug },
    url,
  } = yield select(selectMatch);

  const redirect = yield select(selectRedirect);

  if (redirect) {
    yield put(replace(redirect));
    return;
  }

  if (yield select(selectHasFetchedActiveContent)) {
    return;
  }

  if (isArchive) {
    yield put(actionRequestArchive());
    try {
      const content = yield fetchArchive();
      if (content) {
        content.appSpecificMeta = { hasLocationChanged: true };
      }
      yield put(actionReceivePrimaryContent(content));
    } catch (error) {
      reportWordPressFetchingError('Error when fetching archive', error);
      yield put(actionReceiveError(error));
      // eslint-disable-next-line no-useless-return -- safer to have return here if we have more code after the if else later
      return;
    }
  } else {
    yield put(actionRequestContentItem());
    try {
      const content = yield call(fetchContentItem, {
        articleUrl: url,
        language,
        slug,
      });
      if (content) {
        content.appSpecificMeta = { hasLocationChanged: true };
      }
      yield put(actionReceivePrimaryContent(content));
    } catch (error) {
      reportWordPressFetchingError('Error when fetching content item', error);
      yield put(actionReceiveError(error));
      // eslint-disable-next-line no-useless-return -- safer to have return here if we have more code after the if else later
      return;
    }
  }
}

/**
 * Emit request next page side effects.
 * @param {function} fetchArchive
 */
function* watchRequestNextPage() {
  const match = yield select(selectMatch);
  const { isArchive } = match;
  if (!isArchive) {
    return;
  }

  if (yield select(selectHasFetchedActiveContent)) {
    return;
  }

  yield put(actionRequestNextPageFetchInitiated());

  try {
    const content = yield fetchArchive();
    if (content) {
      content.appSpecificMeta = { hasLocationChanged: false };
    }
    yield put(actionReceivePrimaryContent(content));
  } catch (error) {
    reportWordPressFetchingError('Error when fetching archive next page', error);
    yield put(actionReceiveError(error));
  }
}

// Module Exports
export { requestSiteOptions, rootSaga, watchLocationChanges, watchRequestNextPage };
