const makeFetchWithTimeout =
  (timeout) =>
  (url, options = {}) => {
    const timeoutSignal = AbortSignal.timeout(timeout);
    const { signal = timeoutSignal } = options;
    return fetch(url, { ...options, signal });
  };

const makeFetchWithRetry =
  (fetchFunction = fetch) =>
  async (...parameters) => {
    const result = await fetchFunction(...parameters);
    if (!result.ok && result.status >= 500) {
      return fetchFunction(...parameters);
    }
    return result;
  };

const makeFetchWithTimeoutAndRetry = (timeout) => makeFetchWithRetry(makeFetchWithTimeout(timeout));

export { makeFetchWithTimeoutAndRetry };
