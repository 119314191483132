import { getUrl } from './utils.mjs';
import * as getEndpoints from './endpoints.mjs';

const getBoundEndpoints = (baseUrl) => {
  const boundGetters = {};

  for (const [name, getter] of Object.entries(getEndpoints)) {
    boundGetters[name] = getter(getUrl(baseUrl));
  }
  return boundGetters;
};

export { getBoundEndpoints };
