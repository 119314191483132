// External Imports
import compose from 'lodash/fp/flow.js';
import get from 'lodash/fp/get.js';
import map from 'lodash/fp/map.js';
import range from 'lodash/fp/range.js';
import uniq from 'lodash/fp/uniq.js';
import { compile } from 'path-to-regexp';
import { stringify } from 'query-string';
import { createSelector } from 'reselect';

// Internal Imports
import { createSelectContentItem } from './createSelectContentItem.mjs';
import { selectActivePagination } from './selectActivePagination.mjs';
import { selectContentArchiveBase } from './selectContentArchiveBase.mjs';
import { selectMatch } from './selectMatch.mjs';
import { selectPathMap } from './selectPathMap.mjs';
import { selectTerm } from './selectTerm.mjs';
import { createSelectTranslation } from './createSelectTranslation.mjs';
import { DEFAULT_SEARCH_ORDER } from '../config/constants.mjs';

// Local Functions
const selectContentArchivePageMeta = createSelector(
  [selectContentArchiveBase, selectMatch, createSelectTranslation],
  (meta, { query }, getTranslation) => {
    if (!meta) {
      return {
        controls: {},
        pages: {},
      };
    }

    const {
      order,
      filters: { term },
      pages,
    } = meta;

    const toControl = (controlMap, selectedPath) => ({
      options: Object.keys(controlMap).map((value) => ({
        label: getTranslation(value, controlMap[value]),
        value,
      })),
      selected: get(selectedPath, query) ?? DEFAULT_SEARCH_ORDER,
    });

    const controls = {
      order: toControl(order, 'order'),
    };
    for (const taxonomy of Object.keys(term)) {
      controls[taxonomy] = toControl(term[taxonomy], taxonomy);
    }

    return {
      controls,
      pages,
    };
  },
);

const selectPaginationData = createSelector(
  [selectActivePagination, selectMatch, selectPathMap],
  ({ totalPages, currentPage }, { params, query, pathType }, pathMap) => ({
    currentPage,
    hasMore: totalPages ? totalPages > 1 && currentPage < totalPages : false,
    paginationLinks: range(1, totalPages + 1).map((value, pageIndex) => {
      const toPath = compile(pathMap[pathType]);
      const basePath = toPath({
        ...params,
        page: pageIndex + 1,
      });
      const queryString = stringify(query) ? `?${stringify(query)}` : '';

      return `${basePath}${queryString}`;
    }),
    totalPages,
  }),
);

const selectContentArchiveItems = createSelector(
  [selectActivePagination, selectContentArchivePageMeta, selectMatch, (state) => state],
  ({ currentPage }, { pages }, { params: { page: routePage } }, state) => {
    // Prevent a malicious payload from crashing the server with a huge page number
    const maxPageCount = 1_000;
    if (currentPage > maxPageCount) {
      // eslint-disable-next-line no-console -- Warning for debugging
      console.warn(
        `WARNING: page ${currentPage} exceeds max of ${maxPageCount}; short-circuiting and returning no results`,
      );
      return [];
    }

    const mapItem = ({ type, id }) => createSelectContentItem(type)(state, id);

    // If we're viewing only the content for a specific page, determined by route :page param
    if (routePage && pages[routePage] && Array.isArray(pages[routePage])) {
      return pages[routePage].flatMap((page) => mapItem(page));
    }

    // If we're using the loadMore fuctionality
    return range(1, currentPage + 1).flatMap((page) => {
      const references = pages[page] || [];
      return references.map((reference) => mapItem(reference));
    });
  },
);

/**
 * @typedef {Object} ContentArchive
 * @property {object[]} items
 * @property {function} component
 * @property {object} pagination
 *
 * Select and compute a content archive shape object.
 * @param {object} state Redux state
 * @return {ContentArchive}
 */
const selectContentArchive = createSelector(
  [selectContentArchivePageMeta, selectContentArchiveItems, selectPaginationData, selectTerm],
  (pageMeta, items, pagination, term) => {
    const contentTypes = compose(map('metadata.type'), uniq)(items);
    const contentType = contentTypes.length === 1 ? contentTypes[0] : undefined;
    const typeLabel = contentType ? get('metadata.postTypeObject.label', items[0]) : undefined;

    return {
      contentType,
      items,
      pageMeta,
      pagination,
      term,
      typeLabel,
    };
  },
);

// Module Exports
export { selectContentArchive, selectContentArchiveItems };
