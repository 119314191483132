// Internal Imports
import {
  getApigatewayBaseUrl,
  getAwsSessionEndpoint,
  getAwsSessionMethod,
} from '../../utils/url.mjs';

// Local Functions
const authenticatedRequest = async (path, options = {}) => {
  // eslint-disable-next-line import/no-unresolved -- correct despite the warning TODO: see why it's incorrectly reported
  const { fetch, fromBabbelSession } = await import('@lessonnine/api-client.js/sigv4');
  const response = await fetch(`${getApigatewayBaseUrl()}/${path}`, {
    ...options,
    awsCredentials: fromBabbelSession({
      awsSessionEndpoint: getAwsSessionEndpoint(),
      awsSessionMethod: getAwsSessionMethod().toLowerCase(),
    }),
  });
  if (response.ok) {
    return response.json();
  }
  throw new Error(`HTTPError fetching ${path}: ${response.statusCode}`);
};

// Module Exports
export { authenticatedRequest };
