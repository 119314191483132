// Internal Imports
import { englishAlternatives } from '../config/types/language.mjs';

// Local Variables

const englishFormatting = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};

const defaultFormatting = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

// Local Functions
/**
 * Return the correct date format for an Article page
 *
 * @param  {string} date - Post Date
 * @param  {string} languageCode - Edition Language
 * @return {string}
 */
function formatDate(rawDate, languageCode) {
  const date = new Date(rawDate);
  const formattingConfig = englishAlternatives.includes(languageCode)
    ? englishFormatting
    : defaultFormatting;
  return date.toLocaleDateString(languageCode.replace('_', '-'), formattingConfig);
}

// Module Exports
export { formatDate };
