// Internal Imports
import {
  AUTHOR_ARCHIVE,
  CATEGORY_ARCHIVE,
  CONTENT_ITEM_SINGLE,
  HOME,
  SEARCH_ARCHIVE,
  TERM_ARCHIVE,
} from '../config/types/path.mjs';
import { getApigatewayBaseUrl, getHostname, isBabbelHostname, isTrackingDisabled } from './url.mjs';
import { getTrackingUuid } from './getTrackingUuid.mjs';

// Local Variables
const pageType = {
  [AUTHOR_ARCHIVE]: 'author',
  [CATEGORY_ARCHIVE]: 'category',
  [CONTENT_ITEM_SINGLE]: 'article',
  [HOME]: 'index',
  [SEARCH_ARCHIVE]: 'search_results',
  [TERM_ARCHIVE]: 'tag',
};

const DEFAULT_EVENT_VERSION = 4;

// Local Functions
const hasMobileTracker = () => Boolean(window?.MobileAppTracker);

const trackWithMobileTracker = ({ name, version, ...payload }) => {
  window.MobileAppTracker.track(name, version, JSON.stringify(payload));
};

const ignoringLoadFailedError =
  (asyncCall) =>
  async (...parameters) => {
    try {
      return await asyncCall(...parameters);
    } catch (error) {
      if (error instanceof TypeError && error.message === 'Load failed') {
        // eslint-disable-next-line consistent-return -- optional return
        return;
      }
      throw error;
    }
  };

const trackWithEventsEndpoint = (event) => {
  const apigatewayBaseUrl = getApigatewayBaseUrl();
  const apiUrl = `${apigatewayBaseUrl}/v1/events`;
  const postData = {
    events: [event],
  };
  const stringifiedPostData = JSON.stringify(postData);
  // Try sendBeacon() or fallback to fetch() if it's not supported
  // TODO: we could use fetch keepalive instead, but for now it's not supported in Firefox
  // once it is, we can get rid of `sendBeacon` fully and only use fetch with keepalive
  if (typeof navigator.sendBeacon === 'function') {
    try {
      if (navigator.sendBeacon(apiUrl, stringifiedPostData)) {
        return Promise.resolve();
      }
      // eslint-disable-next-line no-empty -- We want to ignore any errors happening here as we default to fetch later in the file
    } catch {}
  }

  return fetch(apiUrl, {
    body: stringifiedPostData,
    cache: 'no-store',
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  });
};

function track(eventPayload) {
  // We can't track events locally as tracking_uuid and uuid won't be available
  if (isTrackingDisabled || !isBabbelHostname(getHostname())) {
    return Promise.resolve();
  }
  const trackingUuid = getTrackingUuid();
  try {
    const event = {
      created_at: new Date().toISOString(),
      ...(trackingUuid ? { tracking_uuid: trackingUuid } : {}),
      ...eventPayload,
    };
    // We receive a lot of Load failed error on Safari iOS/Mac, this is probably due to privacy settings of our users
    // so it should be safe to ignore Load failed errors for event tracking
    return hasMobileTracker()
      ? trackWithMobileTracker(event)
      : ignoringLoadFailedError(trackWithEventsEndpoint)(event);
  } catch (error) {
    return Promise.reject(error);
  }
}

function trackPageEvent(eventPayload) {
  const currentMode = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  return track({
    ui_theme: currentMode,
    version: DEFAULT_EVENT_VERSION,
    ...eventPayload,
  });
}

function trackExperimentTriggered({ locale, name, series, value }) {
  return track({
    caller: 'babbel-magazine.isomorphic.spa',
    experiment_name: name,
    experiment_owner: 'pineapples',
    experiment_series: series,
    experiment_value: value,
    locale,
    name: 'experiment:triggered',
    version: 5,
  });
}

// Module Exports
export { pageType, trackExperimentTriggered, trackPageEvent };
