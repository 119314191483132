// External Imports
import { createSelector } from 'reselect';

// Internal Imports
import { editionPathTypeOrder, HOME } from '../config/types/path.mjs';
import { checkUsEnglish } from '../utils/checkUsEnglish.mjs';
import {
  isAbsolute,
  isBabbelHostname,
  prependEditionHostname,
  getCurrentEditionBaseUrl,
  hostIsStaging,
} from '../utils/url.mjs';
import { getFirstMatch } from './selectFirstMatch.mjs';
import { selectPathMap } from './selectPathMap.mjs';

// Local Functions

// Given a link (absolute or relative), returns the absolute link or the correct relative link in the current edition
const selectGetEditionPath = createSelector([selectPathMap], (pathMap) => (link) => {
  const linkParts = new URL(link, getCurrentEditionBaseUrl());
  const isHttpLink = /^https?:$/.test(linkParts.protocol); // For example: email links are non-HTTP
  const isAbsoluteLink = isAbsolute(link);
  const { hostname, pathname, search } = linkParts;
  const isAbsoluteNonBabbelLink =
    isAbsoluteLink && !isBabbelHostname(hostname) && !hostIsStaging(hostname);

  if (!isHttpLink || isAbsoluteNonBabbelLink) {
    return link;
  }

  const magazineRouteMatch = getFirstMatch(editionPathTypeOrder, pathMap, pathname);

  if (!magazineRouteMatch) {
    if (!isAbsoluteLink) {
      throw new Error(`Unknown relative link ${link}`);
    }
    return link;
  }

  const {
    params: { language },
    pathType,
  } = magazineRouteMatch;

  /* 
      For the homepage, we have two english versions (one catered to the US at `en-US`).
      For the rest of the pages though, we only have `en`, so here before computing the link for the homepage
      we check for US English so we can potentially replace `/en/magazine` with `/en-US/magazine`
    */
  const newLanguageCode = HOME === pathType ? checkUsEnglish(language) : language;
  const pathWithoutTrailingSlash = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
  const pathWithRightLanguageCode =
    language === newLanguageCode
      ? pathWithoutTrailingSlash
      : pathWithoutTrailingSlash.replace(`/${language}/`, `/${newLanguageCode}/`);
  return prependEditionHostname(newLanguageCode, pathWithRightLanguageCode) + search;
});

// Module Exports
export { selectGetEditionPath };
