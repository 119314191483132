import { makeFetchWithTimeoutAndRetry } from '../../utils/network.mjs';

const fetchWithTimeoutAndRetry = makeFetchWithTimeoutAndRetry(13_000);

const baseFetch = async (url, options = {}) => {
  const response = await fetchWithTimeoutAndRetry(url, options);
  if (response.ok) {
    return response.json();
  }
  // TODO: statusText only reflects the HTTP error status text, maybe we should also try to access the text of the error?
  throw new Error(`HTTPError ${response.status} fetching ${url}: ${response.statusText}`);
};

const getUrl = (baseUrl) => (path) => new URL(`${baseUrl}${path}`);

const extractPaginationFromResponse = (response, page) => ({
  currentPage: page,
  total: response.headers.get('X-Wp-Total'),
  totalPages: response.headers.get('X-Wp-Totalpages'),
});

/* 
  WordPress API has two ordering mechanisms:
  - order: can be either asc or desc and is purely time based
  - orderBy: based on internal algorithm, such as 'relevant'
*/
const appendOrderParameter = (url, order) => {
  if (['asc', 'desc'].includes(order)) {
    url.searchParams.append('order', order);
  } else {
    url.searchParams.append('orderby', order);
  }
};

export {
  appendOrderParameter,
  baseFetch,
  extractPaginationFromResponse,
  fetchWithTimeoutAndRetry,
  getUrl,
};
