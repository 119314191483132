// External Imports
import { call, put, select } from 'redux-saga/effects';

// Internal Imports
import { FETCH_USER_SUCCEEDED, FETCH_USER_ERRORED } from '../config/types/action.mjs';
import { selectLocale } from '../selectors/selectLocale.mjs';
import { fetchCurrentUser } from '../services/babbelApi/currentUser.mjs';

// Local Functions
function* fetchUser() {
  try {
    const locale = yield select(selectLocale);
    const userData = yield call(fetchCurrentUser, { locale });
    yield put({ payload: userData, type: FETCH_USER_SUCCEEDED });
  } catch (error) {
    yield put({ payload: error, type: FETCH_USER_ERRORED });
  }
}

// Module Exports
export { fetchUser };
