import { ENGLISH_USA } from '../../config/types/language.mjs';
import { getEditionFromLocale } from '../../selectors/selectEdition.mjs';
import { appendOrderParameter } from './utils.mjs';

// Had to add an indirection in all of the getters here because getUrl requires a JSON file, which requires "with { type: 'json' }" for Playwright
// but isn't fully supported yet outside of Node
const getAuthorEndpoint =
  (getUrl) =>
  ({ name, language, page, perPage }) =>
    getUrl(
      `/babbel/v1/author/${name}?_embed=true&language=${language}&orderby=relevance&page=${page}&per_page=${perPage}`,
    );

const getCategoriesEndpoint =
  (getUrl) =>
  ({ categoryId, slug }) =>
    getUrl(`/wp/v2/${categoryId}?slug=${slug}`);

const getLandingPageEndpoint =
  (getUrl) =>
  ({ language, page, perPage }) => {
    const url = getUrl(
      `/babbel/v1/landing-page/homepage?_embed=true&language=${getEditionFromLocale(
        language,
      )}&orderby=relevance&page=${page}&per_page=${perPage}`,
    );
    if (language === ENGLISH_USA) {
      url.searchParams.append('country', 'us');
    }
    return url;
  };

const getMultiplePostTypeEndpoint =
  (getUrl) =>
  ({ categories, categoryType, order, page, perPage, search, tags, tagType, type }) => {
    const url = getUrl('/wp/v2/multiple-post-type?_embed=true');
    url.searchParams.append('page', page);
    if (perPage) {
      url.searchParams.append('per_page', perPage);
    }
    if (tags) {
      if (!tagType) {
        throw new Error(`Can't fetch tag related posts without knowing the tag type`);
      }
      url.searchParams.append(`${tagType}[]`, tags);
    }
    if (categories) {
      if (!categoryType) {
        throw new Error(`Can't fetch category related posts without knowing the category type`);
      }
      url.searchParams.append(`${categoryType}[]`, categories);
    }
    if (search) {
      url.searchParams.append('search', search);
    }
    if (order) {
      appendOrderParameter(url, order);
    }
    url.searchParams.append('type[]', type);
    return url;
  };

const getOptionsEndpoint = (getUrl) => () => getUrl('/alley-react/v1/options');

const getPostEndpoint =
  (getUrl) =>
  ({ postType, slug }) =>
    getUrl(
      `/wp/v2/${postType}?_embed=true&slug=${slug}&with_coauthors_related_posts=true&with_related_posts=true`,
    );

const getTagsEndpoint =
  (getUrl) =>
  ({ slug, tagsId }) =>
    getUrl(`/wp/v2/${tagsId}?slug=${slug}`);

export {
  getAuthorEndpoint,
  getCategoriesEndpoint,
  getLandingPageEndpoint,
  getMultiplePostTypeEndpoint,
  getOptionsEndpoint,
  getPostEndpoint,
  getTagsEndpoint,
};
