import {
  languageCodeCategoryTaxonomyMap,
  languageCodePostTypeMap,
  languageCodeTagTaxonomyMap,
} from '../../config/types/language.mjs';
import {
  AUTHOR_ARCHIVE,
  CONTENT_ITEM_SINGLE,
  HOME,
  SEARCH_ARCHIVE,
  TERM_ARCHIVE,
} from '../../config/types/path.mjs';
import { ENGLISH_CATEGORIES, ENGLISH_TAGS } from '../../config/types/taxonomy.mjs';
import {
  createContentPayload,
  createSiteOptionsPayload,
  mergeTerms,
} from '../wordPressRestApi/util.mjs';
import { BASE_URL } from './constants.mjs';
import { getBoundEndpoints } from './getBoundEndpoints.mjs';
import { baseFetch, extractPaginationFromResponse, fetchWithTimeoutAndRetry } from './utils.mjs';

const {
  getAuthorEndpoint,
  getCategoriesEndpoint,
  getLandingPageEndpoint,
  getMultiplePostTypeEndpoint,
  getOptionsEndpoint,
  getPostEndpoint,
  getTagsEndpoint,
} = getBoundEndpoints(BASE_URL);

const fetchOptions = async () => {
  const response = await baseFetch(getOptionsEndpoint());
  return createSiteOptionsPayload(response);
};

const fetchTags = ({ slug, tagsId }) => baseFetch(getTagsEndpoint({ slug, tagsId }));

const fetchCategory = ({ categoryId, slug }) =>
  baseFetch(getCategoriesEndpoint({ categoryId, slug }));

const fetchMultiplePostType = async ({ page = 1, ...endpointParameters }) => {
  const url = getMultiplePostTypeEndpoint({
    ...endpointParameters,
    page,
  });
  const response = await fetchWithTimeoutAndRetry(url);
  if (response.ok) {
    const posts = await response.json();
    // eslint-disable-next-line no-underscore-dangle -- current implementation
    posts._paging = extractPaginationFromResponse(response, page);
    return posts;
  }
  throw new Error(`HTTPError ${response.status} fetching ${url}: ${response.statusText}`);
};

const fetchTagsPageInfo = async ({ language, page, perPage, slug }) => {
  const tagsId = languageCodeTagTaxonomyMap[language];
  // The standard WordPress endpoint for the default tag takes the plural despite its content type being singular
  // so keeping the correct name but updating to the plural for fetching
  const tagsApiName = tagsId === ENGLISH_TAGS ? 'tags' : tagsId;
  const tagsReponse = await fetchTags({ slug, tagsId: tagsApiName });
  if (tagsReponse.length === 0) {
    // meta is required for some data processing later
    return { meta: {} };
  }
  const taggedArticles = await fetchMultiplePostType({
    page,
    perPage,
    tags: tagsReponse[0].id,
    tagType: tagsApiName,
    type: languageCodePostTypeMap[language],
  });
  return mergeTerms(
    createContentPayload(taggedArticles, page, { pathType: TERM_ARCHIVE }),
    tagsId,
    tagsReponse,
  );
};

const fetchCategoryPageInfo = async ({ language, page, perPage, slug }) => {
  const categoryId = languageCodeCategoryTaxonomyMap[language];
  // The standard WordPress endpoint for the default category takes the plural despite its content type being singular
  // so keeping the correct name but updating to the plural for fetching
  const categoryApiName = categoryId === ENGLISH_CATEGORIES ? 'categories' : categoryId;
  const categoriesResponse = await fetchCategory({
    categoryId: categoryApiName,
    slug,
  });
  if (categoriesResponse.length === 0) {
    // meta is required for some data processing later
    return { meta: {} };
  }
  const articlesFromCategory = await fetchMultiplePostType({
    categories: categoriesResponse[0].id,
    categoryType: categoryApiName,
    page,
    perPage,
    type: languageCodePostTypeMap[language],
  });
  return mergeTerms(
    createContentPayload(articlesFromCategory, page, { pathType: TERM_ARCHIVE }),
    categoryId,
    categoriesResponse,
  );
};

const fetchAuthor = async (endpointParameters) => {
  const { page } = endpointParameters;
  const url = getAuthorEndpoint(endpointParameters);
  const response = await fetchWithTimeoutAndRetry(url);
  if (response.ok) {
    // When not found, the response doesn't contain a `posts` attribute, default it to empty to match other WP endpoints
    const { posts = [] } = await response.json();
    // eslint-disable-next-line no-underscore-dangle -- current implementation of paging
    posts._paging = extractPaginationFromResponse(response, page);
    return createContentPayload(posts, page, { pathType: AUTHOR_ARCHIVE });
  }
  throw new Error(`HTTPError ${response.status} fetching ${url}: ${response.statusText}`);
};

const fetchSearch = async ({ language, order, page, perPage, search }) => {
  const response = await fetchMultiplePostType({
    order,
    page,
    perPage,
    search,
    type: languageCodePostTypeMap[language],
  });
  return createContentPayload(response, page, { pathType: SEARCH_ARCHIVE });
};

const fetchLandingPage = async ({ language, page, perPage }) => {
  const url = getLandingPageEndpoint({ language, page, perPage });
  const response = await fetchWithTimeoutAndRetry(url);
  if (response.ok) {
    const posts = await response.json();
    const processedPosts = posts.map((post) => ({
      ...post,
      landingPageType: 'homepage',
      language,
    }));
    // eslint-disable-next-line no-underscore-dangle -- current implementation
    processedPosts._paging = extractPaginationFromResponse(response, page);
    const contentPayload = createContentPayload(processedPosts, page, { pathType: HOME });
    contentPayload.meta.refs = contentPayload[languageCodePostTypeMap[language]].map(
      ({ id, metadata }) => ({ id, type: metadata.type }),
    );
    return contentPayload;
  }
  throw new Error(`HTTPError ${response.status} fetching ${url}: ${response.statusText}`);
};

const fetchContentItem = async ({ articleUrl, language, slug }) => {
  let postType = languageCodePostTypeMap[language];
  // The standard WordPress endpoint for the default post takes the plural despite its content type being singular
  // so keeping the correct name but updating to the plural for fetching
  if (postType === 'post') {
    postType = 'posts';
  }
  const url = getPostEndpoint({ postType, slug });
  const articles = await baseFetch(url);

  // If res is still empty, return early
  if (articles.length === 0) {
    return false;
  }

  // TODO: Is this relevant? The original problem mentioned "nested path" which we don't have
  // Maybe we can report a rollbar warning if `articles.length > 1` and monitor for a while,
  // and if we get no warnings we can get rid of the code
  // If nested page, there may be multiple items with equal slugs.
  const response = articles.filter(({ link }) => link.includes(articleUrl));

  // Shim expected _paging property.
  // Response is an array, so adding a property is weird, but this
  // is the behavior of node-wpapi that we rely on and emulate here.
  response._paging = { total: 1, totalPages: 1 }; // eslint-disable-line no-underscore-dangle -- Wordpress API shape

  return createContentPayload(response, undefined, { pathType: CONTENT_ITEM_SINGLE });
};

export {
  fetchAuthor,
  fetchCategoryPageInfo,
  fetchContentItem,
  fetchLandingPage,
  fetchOptions,
  fetchSearch,
  fetchTagsPageInfo,
};
